/* eslint-disable */
import React from 'react';
import type { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { FormRenderProps } from 'react-final-form';
import { useAuth } from 'src/hooks/use-auth';
import {
  FieldConditionActionTypes,
  FieldConditionsI,
  FieldDetailI,
  FieldDetails,
  RootFieldDetailsI,
} from 'src/interfaces/application.interfaces';
import { validateRules } from 'src/pages/application/applicant-validation-creator';

import { convertSectionToLabel } from '../application-logic';
import DynamicField from './dynamic-field';

interface DynamicSectionsI {
  selectedFormDetails: FieldDetailI[];
  fieldDetailType: FieldDetails;
  formProps: FormRenderProps;
  formikProps?: FormikProps<any>;
  FieldComponent?: any;
  required_fields?: string[];
}

const DynamicSections: FC<DynamicSectionsI | any> = (props: DynamicSectionsI) => {
  const { selectedFormDetails, fieldDetailType, formProps, formikProps, FieldComponent, required_fields } = props;
  const { user } = useAuth();
  const getSectionList = () => {
    const b = {};
    selectedFormDetails?.forEach((s) => {
      const section = s.section;
      // @ts-ignore
      if (b[section]) {
        // @ts-ignore
        b[section].push(s);
      } else {
        // @ts-ignore
        b[section] = [s];
      }
    });
    return b;
  };

  const getSortedSectionList = (sf: any) => {
    const sections = Object.keys(sf);
    for (const s of sections) {
      const fields = sf[s];
      sf[s] = fields?.sort((a: any, b: any) => a?.default_details?.order - b?.default_details?.order);
    }
    return sf;
  };

  const showEmpty = (key: any) => <React.Fragment key={`${key}-empty`}></React.Fragment>;

  const showField = (id: any, objSchema: any) => {
    if (FieldComponent) {
      return FieldComponent(id, formProps, objSchema, formikProps);
    } else {
      return (
        <DynamicField
          key={`dynamic_field_${id}`}
          formProps={formProps}
          objSchema={objSchema}
          id={id}
          required_fields={required_fields}
        />
      );
    }
  };

  const showBasedOnCondition = (field: any, formProps: FormRenderProps, id: any) => {
    const { condition } = field[fieldDetailType];
    if (condition && !isEmpty(condition)) {
      const selectedFieldObj: RootFieldDetailsI = JSON.parse(JSON.stringify(field[fieldDetailType]));
      const conditions: FieldConditionsI = selectedFieldObj.condition;
      const actionType: FieldConditionActionTypes = conditions.type;
      const ruleIds = conditions.rules.map((r: any) => r.id);
      const formValues = formProps.values;
      const fieldValues = ruleIds.map((ruleId) => formValues[ruleId]);
      const rule = validateRules(selectedFieldObj.condition, ruleIds, fieldValues);
      const ActionTypesToShow = [
        FieldConditionActionTypes.show,
        FieldConditionActionTypes.require,
        FieldConditionActionTypes.optional,
      ];
      if (rule && ActionTypesToShow.includes(actionType)) {
        if (actionType === FieldConditionActionTypes.require) {
          selectedFieldObj.required = true;
          return showField(id, selectedFieldObj);
        } else {
          return showField(id, selectedFieldObj);
        }
        return;
      } else {
        return showEmpty(id);
      }
    } else {
      const objSchema = field[fieldDetailType];
      return showField(id, objSchema);
    }
  };

  const sectionList = getSectionList();
  const sortedSectionList = getSortedSectionList(sectionList);

  const sortSections = (s: any) => {
    const sectionOrder = [
      'basicFormFields',
      'identificationFields',
      'housingFields',
      'employmentFields',
      'purchaseFields',
      'bankingFields',
      'coApplicantFields',
    ];
    const updated: any[] = [];
    sectionOrder.forEach((section) => {
      if (s.includes(section)) {
        updated.push(section);
      }
    });
    return updated;
  };

  const sections = Object.keys(sectionList);
  const sortedSections = sortSections(sections);

  return (
    <>
      {sortedSections.map((section, index) => {
        return section !== 'coApplicantFields' ? (
          <React.Fragment key={`${section}-sections-${index}`}>
            <Grid item xs={12} style={{ textAlign: 'left', position: 'relative' }} mt={2}>
              <Typography sx={{ background: 'white', textTransform: 'uppercase', color: '#243F8F' }} variant="h6">
                {convertSectionToLabel(section)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                // mx: { xs: 0, md: 5 },
                // borderTop: '1px solid #000',
                // borderColor: '#243F8F66',
                padding: 3,
              }}
            >
              <Grid container direction={'row'} spacing={3} style={{ marginTop: 2 }}>
                {sortedSectionList[section].map((f: any) => {
                  if (f?.section === 'coApplicantFields') {
                    return;
                  }
                  const { id } = f;
                  return showBasedOnCondition(f, formProps, id);
                })}
              </Grid>
            </Grid>
          </React.Fragment>
        ) : null;
      })}
    </>
  );
};

export default DynamicSections;
