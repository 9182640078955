import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Seo } from 'src/components/seo';
import { E2EChunkedLenderDisclosuresI } from 'src/e2e-redesign/business-logic/e2e-disclosure-logic';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { providerDiscLogic_MultiFuncsAndVals } from 'src/e2e-redesign/views/ProviderDisclosures/logic/provider-disclosures-logic';
import { Disclosure } from 'src/interfaces/disclosures.interfaces';
import * as Yup from 'yup';

import PageIntro from '../../../components/PageIntro';
import SingleLogo from '../../../components/SingleLogo';
import ThemedContainer from '../../../components/ThemedContainer';
import styles from '../ProviderDisclosures.module.css';
import CustomCheckbox from './CustomCheckBox';

export interface MultiProviderDisclosuresProps {
  selectedDisclosures: E2EChunkedLenderDisclosuresI | Disclosure | Disclosure[] | undefined;
  routingPackage: E2eLogicObjectsWithAasoParams;
}

const snapDiscOrder = (disclosures: Disclosure[]) => {
  const order = ['snap', 'snap_phone_opt_in', 'snap_terms_and_conditions'];

  return disclosures.sort((a: any, b: any) => {
    if (a.api_disclosure_name && b.api_disclosure_name) {
      const indexA = order.indexOf(a.api_disclosure_name);
      const indexB = order.indexOf(b.api_disclosure_name);
      return indexA - indexB;
    }
    return a;
  });
};

const handleOrderDisclosures = (disclosures: Disclosure[]) => {
  const disc = disclosures[0];
  if (disc) {
    const discLenderId = disc.lender_id;
    switch (discLenderId) {
      case 16:
        return snapDiscOrder(disclosures);
      default:
        return disclosures;
    }
  }
  return disclosures;
};
export default function MultiProviderDisclosures(props: MultiProviderDisclosuresProps) {
  const { selectedDisclosures } = props;
  const theme = useTheme();
  const fnv = providerDiscLogic_MultiFuncsAndVals(props.routingPackage, selectedDisclosures);
  const { handleMultiDiscAccept, handleGoBackDisclosures, transformName } = fnv.fn;
  const { disclosures: discs, showGoBackButton } = fnv.v;
  const disclosures = handleOrderDisclosures(discs);
  if (!selectedDisclosures) return <></>;
  const logo = {
    src: disclosures[0]?.logo || '',
    alt: disclosures[0]?.name || '',
  };

  const initialValues = disclosures.reduce((acc, disclosure) => {
    acc[transformName(disclosure.name)] = false;
    return acc;
  }, {} as { [key: string]: boolean });

  const validationSchema = Yup.object(
    disclosures.reduce((acc, disclosure) => {
      acc[transformName(disclosure.name)] = Yup.boolean().oneOf([true], 'You must agree to the terms above.');
      if (disclosure.opt_in_marketing) {
        acc[transformName(disclosure.name)] = Yup.boolean();
      }
      return acc;
    }, {} as { [key: string]: Yup.BooleanSchema }),
  );
  return (
    <>
      <Seo title="Disclosure Certification" />

      <ThemedContainer>
        <PageIntro title="Disclosure Certification" />
        <Box mb={2} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <SingleLogo image={logo} />
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleMultiDiscAccept(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, values, isValid, validateForm }) => {
            // This effect will run every time `values` changes and will check the validity of the form
            React.useEffect(() => {
              validateForm();
            }, [values, validateForm]);

            // Determine if all checkboxes are checked
            const allChecked = disclosures.every(
              (disclosure) => disclosure.opt_in_marketing || values[transformName(disclosure.name)],
            );

            return (
              <Form>
                {disclosures.map((disclosure) => (
                  <Box key={disclosure.name} mb={6}>
                    <Typography variant="h2" component="h2" mb={2}>
                      {disclosure.name}
                    </Typography>
                    <Box
                      sx={{
                        border: '1px solid #E0E8F5',
                        borderRadius: '15px',
                        padding: '10px',
                        // height: { xs: '450px', md: '300px' },
                        maxHeight: { xs: '450px', md: '300px' },
                        overflowY: 'scroll',
                        marginBottom: '30px',
                        // TODO: Add themed scrollbar to a global css file
                        '&::-webkit-scrollbar': {
                          width: '12px',
                        },
                        '&::-webkit-scrollbar-track': {
                          background: '#ffffff',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: '#E0E8F5',
                          borderRadius: '15px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                          background: '#B0C4DE',
                        },
                      }}
                    >
                      {disclosure?.dynamic_link ? (
                        <iframe
                          className={styles.dialogContent}
                          src={disclosure?.dynamic_link}
                          title={disclosure.name}
                          style={{ width: '100%', height: '100%', border: 'none' }}
                        />
                      ) : (
                        <div
                          className={styles.dialogContent}
                          dangerouslySetInnerHTML={{ __html: disclosure?.document ? disclosure.document : '' }}
                        />
                      )}
                    </Box>
                    {/* 
                    Created a custom checkbox component to fix an error with Formik's CheckboxWithLabel 
                    when used with arrays of checkboxes 
                  */}
                    <Field
                      component={CustomCheckbox}
                      name={transformName(disclosure.name)}
                      type="checkbox"
                      label={'I agree to the terms above.'}
                      // Label={{ label: 'I agree to the terms above.' }}
                      indeterminate={false}
                    />
                    <ErrorMessage
                      name={transformName(disclosure.name)}
                      component="div"
                      render={(msg) => <div style={{ color: theme.palette.error.main }}>{msg}</div>}
                    />
                  </Box>
                ))}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: { md: 'flex-start' },
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    color="warning"
                    size="large"
                    type="submit"
                    disabled={isSubmitting || !allChecked || !isValid}
                  >
                    Continue
                  </Button>
                  {showGoBackButton && (
                    <Button
                      variant="text"
                      sx={{ color: theme.palette.text.primary, textDecoration: 'underline' }}
                      onClick={handleGoBackDisclosures}
                    >
                      Back
                    </Button>
                  )}
                </Box>
              </Form>
            );
          }}
        </Formik>
      </ThemedContainer>
    </>
  );
}
