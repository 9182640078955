import React, { useEffect, useState } from 'react';
import { GroupedOfferI } from 'src/e2e-redesign/views/LenderResponses/logic/provider-response-logic';
import NewDumbLenderResponseTable from 'src/e2e-redesign/views/LenderResponses/new-dumb-lender-response-table';
import { LendersI } from 'src/interfaces/lenders.interfaces';
import {
  ApplicationSubmissionStateDto,
  AppSubLenderResponseE,
  LenderOffersI,
} from 'src/interfaces/submissions.interfaces';

interface E2eDashboardResponseHandlerProps {
  groupedOffer: GroupedOfferI;
  index: number;
}

const E2eDashboardResponseHandler: React.FC<E2eDashboardResponseHandlerProps> = (
  props: E2eDashboardResponseHandlerProps,
) => {
  const { groupedOffer, index } = props;
  const offer = groupedOffer.offers[0];
  const [showEcoa, setShowEcoa] = useState<boolean>(false);
  const offerDetails: LenderOffersI | undefined = offer?.offer_details;
  const submissionDetails: ApplicationSubmissionStateDto = offer.sub_details;
  const offerLender: LendersI = offer.lender;
  const lenderDisclosures = offer.lender_disclosures;
  const [selectedOffer, setSelectedOffer] = useState<string>('');
  const isPending = submissionDetails.lender_response === AppSubLenderResponseE.pending;

  useEffect(() => {
    const showEcoaStatuses = [AppSubLenderResponseE.rejected, AppSubLenderResponseE.soft_decline];
    if (showEcoaStatuses.includes(submissionDetails.lender_response)) {
      setShowEcoa(true);
    }
  }, [submissionDetails]);

  return (
    <>
      <NewDumbLenderResponseTable
        submissionDetails={submissionDetails}
        index={index}
        offerLender={offerLender}
        lenderDisclosures={lenderDisclosures}
        offerDetails={offerDetails}
        offer={offer}
        isPending={isPending}
        selectedOffer={selectedOffer}
        showEcoa={showEcoa}
        // not including
        redirectUrl={undefined}
        showUpdateStatus={false}
        handleUpdateStatus={() => {}}
        handleAcceptAndProceed={() => {}}
        handleOfferSelection={() => {}}
        disableActions={true}
      />
    </>
  );
};

export default E2eDashboardResponseHandler;
