import { useCallback, useEffect, useRef, useState } from 'react';
import { sub } from 'date-fns';
import { isEmpty } from 'lodash';
import { E2eBaseCallDetailsI, e2eGetBaseAasoRequests } from 'src/api/query-logic/e2e-query-base-calls';
import { queryE2eForm } from 'src/api/query-logic/query-e2e-form';
import { e2eLogic_handleSubmittedForm, RedirectedOffersI } from 'src/e2e-redesign/business-logic/e2e-business-logic';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { E2eRoutingArgsI, handleE2eFormSubmittedRoutingLogic } from 'src/e2e-redesign/business-logic/e2e-routing-logic';
import { e2eValidationLogic_applicationPage } from 'src/e2e-redesign/business-logic/e2e-validation-logic';
import { ComponentLogicI } from 'src/e2e-redesign/interfaces/e2e-base-interfaces';
import useE2eStore from 'src/e2e-store';
import { ApplicationStage } from 'src/enums/aaso.enums';
import { E2ERoutes, NewE2ERoutes } from 'src/enums/routes.enums';
import { useAuth } from 'src/hooks/use-auth';
import { AASO } from 'src/interfaces/aaso.interfaces';
import { E2eAppSettingsFormPathsI, E2eAppSettingsFormsI } from 'src/interfaces/application-settings.interfaces';
import { FieldDetailI, FieldDetails } from 'src/interfaces/application.interfaces';
import { newGetFormValidationSchema } from 'src/pages/application/applicant-validation-creator';
import { newAutoGenerateForm } from 'src/pages/application/logic/auto-generate-form-logic';
import { formatPhoneNumber, removeSpecialCharacters, stripAllSpecialCharactersAndWhiteSpace } from 'src/utils/general';

export const e2eApp_init = (routingPackage: E2eLogicObjectsWithAasoParams) => {
  const params = routingPackage.params;
  const { store_uuid, e2e_form_id, aaso_id } = params;
  const { e2eStore } = routingPackage;

  const baseCallDetails: E2eBaseCallDetailsI = {
    store_uuid,
    e2e_form_id,
    aaso_id,
  };
  e2eGetBaseAasoRequests(baseCallDetails, e2eStore, true);
};

export interface E2eAppLogicI extends ComponentLogicI {
  fn: {};
  v: {
    canViewPage: boolean;
    formData?: any;
    formIsLoading: boolean;
    formIsError: boolean;
    subTitle: string;
  };
}
export const e2eApp_functionsAndVals = (routingPackage: E2eLogicObjectsWithAasoParams): E2eAppLogicI => {
  const { params, e2eStore, aasoContext } = routingPackage;
  const { aaso_id } = params;
  const aaso = aasoContext.aaso;
  // const app_configs = e2eStore.app_configurations;
  const [canViewPage, setCanViewPage] = useState<boolean>(false);
  const { data, isLoading, isError } = queryE2eForm({
    reqData: { params: { aaso_id } }, // RequestDataI
    queryKey: [aaso_id], // this is a unique key for tstack, if it changes, it will refetch
    setState: e2eStore.setFormValues, // how we store it to the store
    queryOpt: {
      enabled: canViewPage, // this prevents the query from running until the storeUuid is set
    },
  });
  const subTitle = e2eStore?.app_configurations?.current_path?.sub_title;
  useEffect(() => {
    if (
      routingPackage?.e2eStore?.disclosures &&
      routingPackage?.e2eStore?.disclosures?.length > 0 &&
      routingPackage.e2eStore.aaso
    ) {
      const canAccess = e2eValidationLogic_applicationPage(routingPackage);
      if (canAccess) {
        setCanViewPage(true);
      } else {
        setCanViewPage(false);
      }
    }
  }, [e2eStore.application_settings, routingPackage.e2eStore.aaso, routingPackage.e2eStore.disclosures]);

  return {
    fn: {},
    v: {
      canViewPage,
      formData: data,
      formIsLoading: isLoading,
      formIsError: isError,
      subTitle: subTitle ? subTitle : '',
    },
  };
};

export interface E2eAppSectionLogicI extends ComponentLogicI {
  fn: {
    handleSubmitForm: (values: any) => void;
    handleAutoGen: (formRenderProps: any) => void;
    handleNavigateBack: () => void;
    setOpenModal: (val: boolean) => void;
    setForceAddressVerification: (val: boolean) => void;
    setFormValues: (val: any) => void;
  };
  v: {
    validationSchema: any;
    e2eFormPath?: E2eAppSettingsFormPathsI;
    updatedFields: FieldDetailI[];
    required_fields: string[];
    current_stage: ApplicationStage;
    openModal: boolean;
    showLoading: boolean;
    formValues?: any;
    canSubmit: boolean | undefined;
  };
}
export const e2eAppSectionLogic_functionsAndVals = (
  routingPackage: E2eLogicObjectsWithAasoParams,
): E2eAppSectionLogicI => {
  const { navigate, params } = routingPackage;
  const { e2e_form_id } = params;
  const { user } = useAuth();
  const form_values = useE2eStore((state: any) => state.form_values);
  const applicationSettings = useE2eStore((state) => state.application_settings);
  const appConfig = useE2eStore((state) => state.app_configurations);
  const aaso: AASO = useE2eStore((state: any) => state.aaso);
  const [e2eForm, setE2eForm] = useState<E2eAppSettingsFormsI>();
  const [e2eFormPath, setE2eFormPath] = useState<E2eAppSettingsFormPathsI>();
  const [form_field_details, setFormFieldDetails] = useState<any>();
  const [updatedFields, setUpdatedFields] = useState<FieldDetailI[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [forceAddressVerification, setForceAddressVerification] = useState(false);
  const forceAddressVerificationRef = useRef(false);
  const [showLoading, setShowLoading] = useState(false);
  const [formValues, setFormValues] = useState(form_values?.data ? form_values?.data : {});
  const [canSubmit, setCanSubmit] = useState<boolean>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const loadedRef = useRef<boolean>(loaded);
  const isSubmitted = useRef<boolean>(false);

  useEffect(() => {
    loadedRef.current = loaded;
  }, [loaded]);

  useEffect(() => {
    if (user) {
      let data = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        mobile_phone: formatPhoneNumber(user.mobile_phone),
      };
      if (form_values?.data) {
        data = {
          ...data,
          ...form_values.data,
        };
      }
      setFormValues(data);
    }
  }, [user, form_values]);

  useEffect(() => {
    forceAddressVerificationRef.current = forceAddressVerification;
  }, [forceAddressVerification]);

  useEffect(() => {
    // R:TODO E2E P1 - refactor this logic - auto-submit form
    /**
     * This is really screwy, but what this does is
     * it auto-submits the form if the user has already entered all of the information
     * required for this path
     */
    if (
      !isSubmitted.current &&
      updatedFields.length <= 0 &&
      loaded &&
      loadedRef.current &&
      !isEmpty(form_values?.data) &&
      form_values.submitted
    ) {
      isSubmitted.current = true; // Set the flag to true
      setLoaded(false);
      handleSubmitForm(form_values.data);
    }
  }, [updatedFields, form_values, loaded]);

  useEffect(() => {
    if (!e2e_form_id) throw new Error('Missing key params');
    if (applicationSettings && appConfig && appConfig.current_path && aaso) {
      const e2eForm = appConfig.current_form;
      const e2eFormPath = appConfig.current_path;
      const form_field_details = e2eFormPath?.form_details;
      const fv = form_values?.data;
      if (!form_field_details) throw new Error('Missing form field details');
      if (aaso.past_path_ids && aaso.past_path_ids.length >= 1) {
        // R:TODO E2E P1 - refactor this logic - auto-submit form 1
        /**
         * This identifies the fields required for this path
         * - it ONLY applies on the users second go around
         * - it will not apply on the first form
         */
        const updatedFormFieldDetails = [];
        for (const ffd of form_field_details) {
          const formerValue = fv?.[ffd.id];
          if (!formerValue || formerValue?.length <= 0) {
            updatedFormFieldDetails.push(ffd);
          }
        }
        setFormFieldDetails(updatedFormFieldDetails);
      } else {
        // users first go - show all field values
        setFormFieldDetails(form_field_details);
      }
      setE2eForm(e2eForm);
      setE2eFormPath(e2eFormPath);
    }
  }, [applicationSettings, appConfig, aaso]);

  useEffect(() => {
    if (canSubmit !== undefined && aaso) {
      if (!canSubmit) {
        const routingArgs: E2eRoutingArgsI = {
          currentStage: aaso.current_stage,
          currentE2eRoute: NewE2ERoutes.Application, // this doesn't matter
        };
        // user cannot submit, so send them to where they belong
        handleE2eFormSubmittedRoutingLogic(routingPackage, routingArgs);
      }
    }
  }, [canSubmit, routingPackage, aaso, form_values]);

  useEffect(() => {
    // R:TODO E2E P1 - move this logic elsewhere
    // we need to see if the user has the ability to submit... basically is this their first time on this path
    if (aaso && appConfig?.current_path) {
      const currentPathId = appConfig.current_path.id;
      const pastPathIds = aaso.past_path_ids;
      setCanSubmit(true);
      if (pastPathIds && pastPathIds.includes(currentPathId)) {
        setCanSubmit(false);
      } else {
        setCanSubmit(true);
      }
    }
  }, [aaso, appConfig?.current_path]);

  useEffect(() => {
    const addAlwaysRequiredFields = () => {
      /**
       * With the new applicant accounts, we need to include this, so that we are always capturing the necessary
       * information.
       */
      const clone = [];
      form_field_details?.forEach((sf: any) => {
        if (sf.always_required) {
          const exists = form_field_details.find((sfd: any) => sfd.id === sf.id);
          if (!exists) {
            clone.push({ ...sf, final_details: sf?.final_details || sf.default_details });
          }
        }
      });
      if (form_field_details) {
        clone.push(...form_field_details);
      }
      return clone;
    };
    // const checkToSeeIfUpdatedFieldsAlreadyHaveValues = (warf: any[]): any[] => {
    //   const newUpdatedShit: any[] = [];
    //   if (form_values?.data && withAllRequiredFields) {
    //     const keys = Object.keys(form_values.data);
    //     const data = form_values.data;
    //     warf.forEach((w) => {
    //       if (!data[w.id]) newUpdatedShit.push(w);
    //     });
    //     return newUpdatedShit;
    //   } else {
    //     return warf;
    //   }
    // };

    if (form_field_details && form_values) {
      const withAllRequiredFields = addAlwaysRequiredFields();
      setUpdatedFields(withAllRequiredFields);
      setLoaded(true);
    }
  }, [form_field_details, form_values]);

  const required_fields = e2eFormPath?.required_fields || [];

  // R:TODO E2E P4 REVISE - need to add record type to validation and currency validation
  const validationSchema = newGetFormValidationSchema(updatedFields, FieldDetails.final_details, required_fields);

  // R:TODO E2E P4: Add co-applicant section and consent capture to the DynamicSection

  const handleNavigateBack = () => navigate(`../${E2ERoutes.LenderDisclosure}`);

  const handleAutoGen = (formRenderProps: any) => {
    const initValues = form_values || {};
    form_field_details.forEach((ffd: any) => {
      //@ts-ignore
      initValues[ffd.id] = '';
    });
    // R:TODO E2E P0 -  make sure this does not show in prod
    const filledOutFields = newAutoGenerateForm(initValues, form_field_details, FieldDetails.final_details);
    const form = formRenderProps.form;
    const keys = Object.keys(filledOutFields);
    keys.forEach((k) => {
      form.change(k, filledOutFields[k]);
    });
  };

  const handleSubmitForm = useCallback(
    async (values: any) => {
      let allValues = values;
      if (!isEmpty(form_values)) {
        allValues = {
          ...form_values,
          ...values,
        };
      }
      setShowLoading(true);
      await e2eLogic_handleSubmittedForm(allValues, routingPackage, setOpenModal, forceAddressVerificationRef.current);
      setShowLoading(false);
    },
    [form_values],
  );

  return {
    fn: {
      handleSubmitForm,
      handleAutoGen,
      handleNavigateBack,
      setOpenModal,
      setForceAddressVerification,
      setFormValues,
    },
    v: {
      validationSchema,
      e2eFormPath,
      updatedFields,
      required_fields,
      current_stage: aaso?.current_stage,
      openModal,
      showLoading,
      formValues,
      canSubmit,
    },
  };
};
