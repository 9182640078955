import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Seo } from 'src/components/seo';
import {
  E2eLogicObjectsWithAasoParams,
  e2eLogicUtils_getRoutingLogicObjects,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import ProviderDetailsDrawer from 'src/e2e-redesign/components/ProviderDetailsDrawer/ProviderDetailsDrawer';
import {
  getStartedLogic_functionsAndVals,
  getStartedLogic_init,
} from 'src/e2e-redesign/views/get-started/logic/get-started-logic';
import { E2eAppSettingsFormPathsI } from 'src/interfaces/application-settings.interfaces';

import LogoGrid from '../../components/LogoGrid';
import PageIntro from '../../components/PageIntro';
import ThemedContainer from '../../components/ThemedContainer';
import theme from '../../theme';

export default function LetsGetStarted() {
  const routingPackage: E2eLogicObjectsWithAasoParams = e2eLogicUtils_getRoutingLogicObjects();
  getStartedLogic_init(routingPackage);
  const fnv = getStartedLogic_functionsAndVals(routingPackage);
  const { handleRouting, handleGoBack, toggleDrawer } = fnv.fn;
  const { multi_paths_exist, disclosureLogos, drawerState, e2ePath } = fnv.v;

  /**
   * - When they hit "go to provider disclosures"
   *      - update AASO object
   *          - capture that they accepted... even though they aren't accepting
   *          - update the page they should be on
   *      - call routeUser function
   *          - we need to route them to the provider disclosures page
   *              - src/e2e-redesign/views/ProviderDisclosures/ProviderDisclosures.tsx
   *
   * - Resources:
   *    - this page is probably the most similar to the "Welcome Aboard" (src/pages/welcome.tsx)
   *      but it's not the same, because they aren't really agreeing there's just additional legal stuff
   *    - See figma Meineke Flow -> Let's Get Started (multiple mocks)
   */

  return (
    <>
      <Seo title="Let's Get Started!" />
      <ThemedContainer>
        <PageIntro title="Let's Get Started!" paragraph="Our Payment Options" />
        <LogoGrid logos={disclosureLogos} />
        <Typography
          component="p"
          variant="body1"
          sx={{ paddingBottom: '20px', textDecoration: 'underline', cursor: 'pointer' }}
          onClick={toggleDrawer}
        >
          Payment Option Details
        </Typography>
        <Typography component="p" variant="body1" sx={{ paddingBottom: '20px', marginTop: '20px' }}>
          Your security is our fundamental priority. The FormPiper Platform is designed to provide to you and to
          securely connect you with various payment options in order to facilitate the processing of your information
          directly within their systems.
        </Typography>
        <Typography component="p" variant="body1" sx={{ paddingBottom: '20px' }}>
          The decision to grant or deny an application is solely determined by each individual payment option.
        </Typography>
        <Typography component="p" variant="body1" sx={{ paddingBottom: '20px' }}>
          FormPiper is neither a lender nor a financial institution – our role is to simplify the process of applying
          for financing or lease to own arrangements. We have no discretion or authority to make any determination
          whether an application is approved or denied and have no influence over any application’s viability at any
          time; all financing decisions are made strictly in accordance with the terms and conditions of each individual
          payment option, over which FormPiper has no authority.
        </Typography>
        <Box
          sx={{
            paddingTop: 2,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'center', md: 'flex-start' },
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Button variant="contained" color="warning" size="large" onClick={handleRouting}>
            Go to payment option disclosures
          </Button>
          {multi_paths_exist && (
            <Button
              type="button"
              onClick={handleGoBack}
              sx={{
                color: theme.palette.text.primary,
                textDecoration: 'underline',
              }}
            >
              Back
            </Button>
          )}
        </Box>
        {e2ePath && (
          <ProviderDetailsDrawer
            isDrawerOpen={drawerState}
            path={e2ePath}
            toggleDrawer={toggleDrawer}
            showPathName={false}
          />
        )}
      </ThemedContainer>
    </>
  );
}
