import * as faker from 'faker';
import moment from 'moment';

const getIdType = (applicationObject: any): any => {
  const selected = applicationObject[Math.floor(Math.random() * applicationObject.length)];
  if (selected && selected.value && selected.value.length > 0) {
    return selected.value;
  } else {
    return getIdType(applicationObject);
  }
};

const getFormComputedDate = (fieldObject: any) => {
  const { tense, params } = fieldObject.faker;
  const [interval, intervalType] = params.split(' ');

  switch (tense) {
    case 'future':
      return moment().add(interval, intervalType);
    case 'past':
    default:
      return moment().subtract(interval, intervalType);
  }
};

export const newAutoGenerateForm = (initValues: any, selectedFormDetails: any, fieldDetailType: any) => {
  const filledOutFields = { ...initValues };

  const keys = Object.keys(initValues);
  keys.forEach((k) => {
    try {
      if (!k.includes('is_married')) {
        const fieldRoot = selectedFormDetails.find((s: any) => s.id === k);
        if (fieldRoot) {
          const fieldObj = fieldRoot[fieldDetailType];
          if (fieldObj && fieldObj.faker) {
            const fakerObj = fieldObj.faker;
            const obj = fakerObj.object;
            const method = fakerObj.method;
            const params = fakerObj.params;
            const leastCommonOptions = fieldObj.least_common_options;
            if (obj === 'manual' || obj === 'userInput') {
              const value = fakerObj.value;
              if (value) {
                filledOutFields[k] = value;
              } else {
                if (leastCommonOptions.length > 0) {
                  filledOutFields[k] = getIdType(leastCommonOptions);
                }
              }
            } else if (obj === 'date') {
              filledOutFields[k] = getFormComputedDate(fieldObj);
            } else {
              if (params) {
                // @ts-ignore
                filledOutFields[k] = faker[obj][method](params);
              } else {
                // @ts-ignore
                filledOutFields[k] = faker[obj][method]();
              }
            }
            if (k === 'spouse_live_with') {
              filledOutFields[k] = 'Y';
            }
            if (k === 'is_married') {
              filledOutFields[k] = 'Y';
            }
            const formatObj = fakerObj.formatObj;
            if (formatObj) {
              switch (formatObj.type) {
                case 'date':
                  filledOutFields[k] = moment(filledOutFields[k]).format(formatObj.format);
                  break;
              }
            }
          }
        }
      }
    } catch (e) {
      // console.log(e, k);
    }
  });

  filledOutFields.is_dummy_data = true;
  return filledOutFields;
};
