import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from '@mui/material';
import { MapPin } from 'react-feather';
import { CleanButton } from 'src/components/clean/button';

interface AddressVerificationDialogProps {
  open: boolean;
  onClose: () => void;
  onProceed: () => void;
  form: any;
}

const AddressValidationModal: React.FC<AddressVerificationDialogProps> = ({ open, onClose, onProceed, form }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="address-verification-dialog"
    fullWidth
    sx={{
      '& .MuiDialog-paper': {
        backgroundColor: 'background.paper',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
      },
    }}
  >
    <DialogTitle>Address Verification Failed</DialogTitle>
    <DialogContent>
      <DialogContentText>
        The address you have entered cannot be verified. Would you like to proceed with the unverified address, or go
        back to edit it?
      </DialogContentText>

      <Paper
        elevation={0}
        sx={{
          mt: 2,
          mb: 2,
          p: 2,
          bgcolor: 'grey.50',
          borderRadius: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
          <MapPin />
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 500, color: 'text.primary' }}>
              {form.address_number} {form.address_street_name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {form.city}, {form.state_name} {form.zip_code}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, paddingTop: 5 }}>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 800, fontSize: 13, color: 'text.primary' }}>
              NOTE: your application will not be processed if you provide an incorrect address
            </Typography>
          </Box>
        </Box>
      </Paper>
    </DialogContent>
    <DialogActions>
      <CleanButton onClick={onClose} color="primary" variant="text">
        Edit Address
      </CleanButton>
      <CleanButton onClick={onProceed} color="primary" variant="contained">
        Proceed with Unverified Address
      </CleanButton>
    </DialogActions>
  </Dialog>
);

export default AddressValidationModal;
