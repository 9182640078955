import parse from 'html-react-parser';

type ReportErrorFunction = (error: Error, errorInfo?: React.ErrorInfo) => void;

export const getUserTimezone = () => {
  try {
    const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return zone;
  } catch (e) {
    console.log('no timezone found');
  }
};

class HttpError extends Error {
  statusCode: number;
  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
    Object.setPrototypeOf(this, HttpError.prototype);
  }
}
export function isError(obj: unknown): obj is HttpError {
  return obj instanceof HttpError;
}

export const removeSpecialCharacters = (str: string) => {
  try {
    // This regex matches anything that's not a lowercase letter, an uppercase letter, or a space
    return str.replace(/[^a-zA-Z ]/g, '');
  } catch (e) {
    console.log(e);
  }
};

export const formatPhoneNumber = (phoneNumber: string) => {
  // Remove all non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return null; // Return null if input is invalid
};

export const stripAllSpecialCharactersAndWhiteSpace = (value: string): string => {
  if (typeof value === 'string') {
    return value.replace(/[^\w]/gi, '').trim();
  }
  return value;
};

export const stripAllSpecialCharacters = (value: string): string => {
  return value.replace(/[^\w\s]/gi, '');
};

export const checkImageSource = (src: string | undefined, usePlaceholder?: boolean): string | false => {
  const img = new Image();
  if (!src) return false;
  img.src = src;
  const placeholder = `https://via.placeholder.com/${img.width || 100}x${img.height || 100}`;
  const returnVal = usePlaceholder ? placeholder : false;
  return img.complete && img.naturalWidth !== 0 ? src : returnVal;
};

export const reportErrorToSlack: ReportErrorFunction = (error, errorInfo = {}) => {
  const text = `Error: ${error.toString()}\nInfo: ${JSON.stringify(errorInfo)}`;

  fetch(process.env.SLACK_WEBHOOK_URL_ERRORS as string, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ text }),
  });
};

export const fetchIPAddress = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error fetching IP address:', error);
  }
};

export const parseHtml = (str: string) => {
  try {
    return parse(str);
  } catch (e) {
    return str;
  }
};
